
import Http from './Http'

export default {
    async getNews(limit = 1000) {
        return await Http.get(`/content/news?limit=${limit}`)
    },
    async removeNews(id) {
        return await Http.delete(`/content/news/${id}`)
    },
    async getVideos(limit = 1000) {
        return await Http.get(`/content/videos?limit=${limit}&all`)
    },
    async getShorts(){
        return await Http.get(`/content/shorts`)
    },
    async removeShorts(id){
       return await Http.delete(`/content/shorts/${id}`)
    },
    async removeVideos(id) {
        return await Http.delete(`/content/videos/${id}`)
    },
    async fireEvent(data) {
        return await Http.post(`/content/fireEvent`, data)
    }
}


